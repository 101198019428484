import React from "react";
import { graphql, Link } from "gatsby";
import { TopBar } from "@launchacademy/voyager";
import _ from "lodash";
import logoImage from "@launchacademy/voyager/dist/images/logo.svg";

import "./css/guide-layout.css";
import "../../css/richContent.css";
import Seo from "../../components/seo";

const BootcampGuideShow = ({ data, location }) => {
  const logo = (
    <Link to="/">
      <img src={logoImage} className="navigation-list__logo" alt="Launch Academy" />
    </Link>
  );
  const { guidePage, allPages } = data;
  const pageLinks = allPages.nodes.map((page) => {
    return (
      <li key={page.id} className="bootcamp-guide__page">
        <Link activeClassName="active" to={`/bootcamp-guide/${page.slug}`}>
          {page.title}
        </Link>
      </li>
    );
  });
  const navLinks = allPages.nodes.map((page) => {
    return (
      <li key={`nav-${page.id}`} className="navigation-list__link">
        <Link activeClassName="active" to={`/bootcamp-guide/${page.slug}`}>
          {page.title}
        </Link>
      </li>
    );
  });
  return (
    <>
      <Seo
        title={guidePage.title}
        bodyClassName="bootcamp-guide-body"
        description={
          guidePage.excerpt ||
          _.truncate(guidePage.content.replace(/(<([^>]+)>)/gi, ""), { length: 130 })
        }
      />
      <TopBar logo={logo} logoAltText="Launch Academy" logoUrl="/">
        <ul className="navigation-list navigation-list__top" role="list">
          {navLinks}
        </ul>
      </TopBar>
      <div className="main-content">
        <div className="container bootcamp-guide">
          <nav className="bootcamp-guide__page-listing">
            <ol className="bootcamp-guide__page-list">{pageLinks}</ol>
          </nav>
          <article className="bootcamp-guide__page-content">
            <h2 className="bootcamp-guide__page-chapter-number">
              Chapter {guidePage.chapterNumber}
            </h2>
            <h1 className="bootcamp-guide__page-title">{guidePage.title}</h1>
            <div className="rich-content" dangerouslySetInnerHTML={{ __html: guidePage.content }} />
          </article>
        </div>
      </div>
      <div id="portal"></div>
    </>
  );
};

export const pageQuery = graphql`
  query GuideShowPage($slug: String!) {
    guidePage: strapiBootcampGuidePage(slug: { eq: $slug }) {
      chapterNumber
      content
      slug
      title
    }
    allPages: allStrapiBootcampGuidePage(sort: { fields: chapterNumber, order: ASC }) {
      nodes {
        slug
        id
        title
      }
    }
  }
`;

export default BootcampGuideShow;
